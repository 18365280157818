import React from "react"

import PickUpComponent from "../components/Application/PickUpComponent"
import ProtectedRouteLayout from "../components/Layout/ProtectedRouteLayout"

const pickUp = () => {
  return (
    <ProtectedRouteLayout>
      <PickUpComponent />
    </ProtectedRouteLayout>
  )
}

export default pickUp
